import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import PasswordStrengthBar from 'react-password-strength-bar';

import {TenantFrontendApi_PostTenantLicence,TenantFrontendApi_DeleteTenantLicence,TenantFrontendApi_DeleteUserLicence,TenantFrontendApi_GetResellerLicenceList,TenantFrontendApi_GetTenant, TenantFrontendApi_GetUser,TenantFrontendApi_UpdateUser,TenantFrontendApi_DeleteUser, TenantFrontendApi_ChangeUserPassword,TenantFrontendApi_ChangeUserLicense, TenantFrontendApi_GetLicenceList} from "./TenantFrontendApi";
import { Translate } from "./Translator"
import Header from "./Header"
import MainMenu from "./MainMenu";
import Footer from "./Footer"
import BlueBar from "./BlueBar";

import HomeLogo from "./Assets/HomeLogo.png"
import CloseLogo from "./Assets/CloseLogo.jpg"

import './Tenant.css';
import './EditUser.css';

function EditUser(){

    const navigate = useNavigate();
    const [mRepaint,SetRepaint]=useState(false)
    const [mUser,SetUser]=useState()
    const [mUserGuid,SetUserGuid]=useState()
    const [mUpdateResult,SetUpdateResult] = useState("")
    const [mUpdateSuccessfully,SetUpdateSuccessfully] = useState(false)
    const [mUserPassword,SetUserPassword] = useState("")
    const [mConfirmPassword,SetConfirmPassword] = useState("")
    const [mChangePasswordResult,SetChangePasswordResult] = useState("")
    const [mChangePasswordSuccessfully,SetChangePasswordSuccessfully] = useState(false)
    const [mPasswordVisible, SetPasswordVisible] = useState(false)
    const [mLicenceList,SetLicenceList] = useState([])
    const [mLicenceGuid,SetLicenceGuid] = useState("")
    const [mExpireLicence,SetExpireLicence] = useState("")
    
    const [mTenantGuid,SetTenantGuid]=useState()
    const [mTenant, SetTenant] = useState("")

    const [mExpireCurrentLicence,SetExpireCurrentLicense] = useState(false)

    const [mAssignNewLicenceError,SetAssignNewLicenceError] = useState("")

    const [mTenantLicenceList,SetTenantLicenceList] = useState()
    const [mAutoBuyingLicense,SetAutoBuyingLicense] = useState(false)


    useEffect(() => {
        
        window.scrollTo(0, 0);

        async function GetUser(aUserGuid) {
            try {
                const UserResponse = await TenantFrontendApi_GetUser(sessionStorage.getItem("token"), aUserGuid);
                SetUser(UserResponse.data);
                SetLicenceGuid(UserResponse.data.LicenceGuid);
                console.log(UserResponse.data.TenantGuid);
                SetTenantGuid(UserResponse.data.TenantGuid); // This will trigger the next useEffect
            } catch (ex) {
                let error = ex;
                let errorCode = null;
                try {
                    if (ex.response && ex.response.status) {
                        errorCode = ex.response.status;
                        console.log(errorCode);
                    }
                    navigate('../error?errorcode=' + errorCode + '&error=user');
                    error = ex.response.data.Exception;
                } catch {}
                console.log("Error: " + error);
            }
        }
    
        async function GetLicenceList() {
            try {
                console.log("Getting Licences");
                const LicenceListResponse = await TenantFrontendApi_GetResellerLicenceList(sessionStorage.getItem("token"));
                console.log(JSON.stringify(LicenceListResponse));
                SetLicenceList(LicenceListResponse.data);
            } catch (ex) {
                let error = ex;
                try {
                    error = ex.response.data.Exception;
                } catch {}
                console.log("Error: " + error);
            }
        }
    
        //Get the parameters from the URL
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        const ciParams = new URLSearchParams();
        for (const [name, value] of params) {
            ciParams.append(name.toLowerCase(), value);
        }
        const userguid = ciParams.get('userguid');
        SetUserGuid(userguid);
        GetUser(userguid);
        GetLicenceList();
    
    }, [navigate]); // First useEffect to get the user and licence list
    
    useEffect(() => {
        async function GetTenant() {
            try {
                console.log("Getting tenant");
                const TenantResponse = await TenantFrontendApi_GetTenant(sessionStorage.getItem("token"), mTenantGuid);
                SetTenant(TenantResponse.data);
            } catch (ex) {
                let error = ex;
                let errorCode = null;
                try {
                    if (ex.response && ex.response.status) {
                        errorCode = ex.response.status;
                        console.log(errorCode);
                    }
                    navigate('../error?errorcode=' + errorCode + '&error=tenant');
                    error = ex.response.data.Exception;
                } catch {}
                console.log("Error: " + error);
            }
        }
    
        async function GetTenantLicenceList() {
            try {
                console.log("Getting Tenant Licences");
                const TenantLicenceListResponse = await TenantFrontendApi_GetLicenceList(sessionStorage.getItem("token"), mTenantGuid);
                SetTenantLicenceList(TenantLicenceListResponse.data);
            } catch (ex) {
                let error = ex;
                try {
                    error = ex.response.data.Exception;
                } catch {}
                console.log("Error: " + error);
            }
        }
    
        if (mTenantGuid) { // Ensure mTenantGuid has been set before calling these functions
            GetTenant();
            GetTenantLicenceList();
        }
    }, [mTenantGuid, navigate]); // Second useEffect depends on mTenantGuid
    
    const SetName = (aName) => {
        if(mUser)
        {
            mUser.Name = aName
            SetRepaint(!mRepaint)
        }
    }

    const SetEmail = (aEmail) => {
        if(mUser)
        {
            mUser.Email = aEmail
            SetRepaint(!mRepaint)
        }

    }

    const SetUserApiSetting = (aUserApiSetting) => {

        const updatedApiSetting = aUserApiSetting.replace(/\n/g, '\r\n');
        SetUser((prevUser) => ({
            ...prevUser,
            ApiSettings: updatedApiSetting
        }));
        SetRepaint(!mRepaint);
        
    }

    const SetIsRecognitionAdmin = (aIsRecognitionAdmin) =>{
        mUser.IsRecognitionAdmin = aIsRecognitionAdmin
        SetRepaint(!mRepaint)
    }

    const SetIsTenantAdmin = (aIsTenantAdmin) =>{
        mUser.IsTenantAdmin = aIsTenantAdmin
        SetRepaint(!mRepaint)
    }

    const SetSaveUserSettingsResultShow = (aMessage) =>{
        //
    }

    const SetPressSaveUserSettings = (aFlag) =>{
        //
    }

    async function UpdateUser() {

        try
        {
            //console.log("Updating user")
            const UpdateUserResponse = await TenantFrontendApi_UpdateUser(sessionStorage.getItem("token"),mUser.UserGuid,mUser.Email,mUser.Name,mUser.ApiSettings,mUser.IsRecognitionAdmin,mUser.IsTenantAdmin,mUser.TenantGuid,mLicenceGuid)
            console.log(JSON.stringify(UpdateUserResponse))
            SetSaveUserSettingsResultShow("The user updated successfully")
            SetUpdateResult("The user updated successfully")
            SetUpdateSuccessfully(true)
            if(mTenantGuid)
            {
                navigate('../tenant?tenantguid=' + mTenantGuid)
            }
            else
            {
                navigate('../user?userguid=' + mUserGuid + '&tenantguid=' + mTenantGuid)
            }
        }

        catch (ex)
        {
            var error = ex;
            try { error = ex.response.data.Exception; } catch {}  
            console.log("Error: " + error);
            SetSaveUserSettingsResultShow("The user is not updated !")
            SetUpdateResult("You are not allow to assign this license to current user")
            SetUpdateSuccessfully(false)
        }
    }

    async function DeleteUser(aUserGuid) {
        try
        {
            //console.log("Deleting user")
            const DeleteUserResponse = await TenantFrontendApi_DeleteUser(sessionStorage.getItem("token"),aUserGuid)
            console.log(DeleteUserResponse)
            if(mTenantGuid)
            {
                navigate('../tenant?tenantguid=' + mTenantGuid)
            }
            else
            {
                navigate('../users')
            }
        }
        catch (ex)
        {
            var error = ex;
            try { error = ex.response.data.Exception;
                console.log(ex.response.data)
             } catch {}  
            console.log("Error: " + error);
        }
    }

    async function ChangeUserPassword(){
        try
        {
            //console.log("Changing user password")
            const ChangeUserPasswordResponse = await TenantFrontendApi_ChangeUserPassword(sessionStorage.getItem("token"),mUserGuid,mUserPassword,mUser.TenantGuid)
            console.log(JSON.stringify(ChangeUserPasswordResponse))
            SetChangePasswordResult("The user password is updated successfully")
            SetChangePasswordSuccessfully(true)
        }

        catch (ex)
        {
            var error = ex;
            try { error = ex.response.data.Exception; } catch {}  
            console.log("Error: " + error);
            SetChangePasswordResult("The user password is not updated correctlly")
            SetChangePasswordSuccessfully(false)
        }
    }

    function handleSaveUserSettings(){
        SetPressSaveUserSettings(true)
        return(
            UpdateUser()
        )
       
    }

    function handleChangePassword(){

        if(mUserPassword && mConfirmPassword)

        {
            if(mUserPassword === mConfirmPassword)
            {
                return(ChangeUserPassword())
            }

            else
            {
                //the passwords are not same 
                SetChangePasswordSuccessfully(false)
                SetChangePasswordResult("The passwords are not same")

            }  
        }
        else
        {
            SetChangePasswordSuccessfully(false)
            SetChangePasswordResult("Both fields should be fill it")  
            
        }
    }

    function handleDeleteUser()
    {
        return(DeleteUser())  
    }
        

    function languageChanged(){
        SetRepaint(!mRepaint)
    }

    function ExpireLicence()
    {
        DeleteUserLicence()
    }

    async function DeleteUserLicence()
    {
        try{
            //console.log("Deleting Licence")
            const DeleteLicecnceResponse = await TenantFrontendApi_DeleteUserLicence(sessionStorage.getItem("token"),mUserGuid,mUser.TenantGuid)
            console.log(DeleteLicecnceResponse)
            SetExpireLicence("")
            navigate('../user?userguid=' + mUserGuid + "&tenantguid=" + mUser.TenantGuid)
            SetRepaint(!mRepaint)
        }
        catch(ex)
        {
            var error = ex;
            try { error = ex.response.data.Exception; } catch {}  
            console.log("Error: " + error);
        }
    }

    async function AssignNewLicense()
    {
        try{
            if(mAutoBuyingLicense)
            {
                try
                    {   
                        const BuyingTenantLicenceResponse = await TenantFrontendApi_PostTenantLicence(sessionStorage.getItem("token"),mTenantGuid,mLicenceGuid,1)
                        console.log(JSON.stringify(BuyingTenantLicenceResponse))
                    }
            
                    catch (ex)
                    {
                        var errorTenantLicenceResponse = ex;
                        try { errorTenantLicenceResponse = ex.response.data.Exception; } catch {}  
                        console.log("Error: " + errorTenantLicenceResponse);
                    }
            }
            
            //console.log("Assigning new licence")
            if(mExpireCurrentLicence)
                {
                    // Expire this license also for the tenant
                    DeleteTenantLicence()

                }
            if(mLicenceGuid === "None")
            {
                DeleteUserLicence()
            }
            else
            {
                const AssignNewLicenseResponse = await TenantFrontendApi_ChangeUserLicense(sessionStorage.getItem("token"),mUserGuid,mUser.TenantGuid,mLicenceGuid)
                console.log(AssignNewLicenseResponse.data)
                SetLicenceGuid("")
                navigate('../user?userguid=' + mUser.UserGuid)
            }
        }
        catch(ex)
        {
            var error = ex;
            
            try { error = ex.response.data.Exception;
                console.log(ex.response.data)
                SetAssignNewLicenceError(ex.response.data)
             } catch {}  
            console.log("Error: " + error);
        } 
    }

    async function DeleteTenantLicence()
    {
        try
        {
            //console.log("Deleting Licence in tenant")
            const DeleteLicecnceResponse = await TenantFrontendApi_DeleteTenantLicence(sessionStorage.getItem("token"),mUser.TenantGuid,mUser.LicenceGuid,1)
            console.log(DeleteLicecnceResponse.data)
            //console.log("Delete licences also in tenant")
        }

        catch(ex){
            console.log(ex.response.data)
            var error = ex;
            try { error = ex.response.data.Exception; } catch {}  
            console.log("Error: " + error);
        }
    }

    function FindLicenceGuidInTenantLicences() {
        // Check if mTenantLicenceList is defined and is an array
        if (!Array.isArray(mTenantLicenceList)) {
            console.error('mTenantLicenceList is not an array or is undefined:', mTenantLicenceList);
            return true; // or handle the error as needed
        }
    
        // Log the values for debugging
        console.log('mTenantLicenceList:', mTenantLicenceList);
        console.log('mLicenceGuid:', mLicenceGuid);
    
        // Find the license
        const result = mTenantLicenceList.find(license => license.LicenceGuid === mLicenceGuid);
        console.log('Find result:', result);
    
        // Check the result and return accordingly
        return result === undefined;
    }

    
        let PageLocation = <span/>
        if (mTenantGuid)
        {
            PageLocation = <tr height="60">
                <td width="40" align="left">
                    <span width="20" align="left"><img alt="Home logo" className="HomeLogo pointer" src={HomeLogo} onClick={() => navigate('/')}/></span>              
                    <span className="underline pointer bold" width="40" align="left" text-align="button" onClick={() => navigate('../reseller')}>{Translate("Tenants")}</span>
                    <span width="20" className="blue" align="center"> / </span>
                    <span className="underline pointer bold" width="20" align="left" onClick={() => navigate('../tenant?tenantguid=' + mTenantGuid)}>{mTenant.Company}</span>
                    <span width="20" className="blue" align="center"> / </span>
                    <span className="underline pointer bold" width="40" align="left" text-align="button" onClick={() => navigate('../tenantusers?tenantguid=' + mTenantGuid)}>{Translate("Users")}</span>
                    <span width="20" className="blue" align="center"> / </span>
                    <span width="50" align="left" className="bold underline pointer" onClick={()=>navigate('../user?userguid=' + mUserGuid + '&tenantguid=' + mTenantGuid)}>{mUser ? mUser.Name : ''}</span>
                    <span width="20" className="blue" align="center"> / </span>
                    <span className="bold">{Translate("Edit")}</span>
                </td>
            </tr>
        }
        else
        {
            PageLocation = <tr height="60">
                <td width="40" align="left">
                    <span width="20" align="left"><img alt="Home logo" className="HomeLogo pointer" src={HomeLogo} onClick={() => navigate('/')}/></span>
                    <span className="underline pointer bold" width="40" align="left" text-align="button" onClick={() => navigate('../users')}>{Translate("Users")}</span>
                    <span width="20" className="blue" align="center"> / </span>
                    <span width="50" align="left" className="bold">{mUser ? mUser.Name : ''}</span>
                    <span width="20" className="blue" align="center"> / </span>
                    <span className="bold">{Translate("Edit")}</span>
                </td>
            </tr>
        }

        return(
            
            <span>

                <Header onLanguageChanged={() => languageChanged()}/>
                
                <MainMenu MainMenuNumber={mTenantGuid ? 1 : 2} />

                <table width="1200" cellPadding={0} cellSpacing={0} align="center">
                <tbody>

                    <tr className="pagetop3">
                        <td height="75" valign="bottom">
                            <table width="100%" cellPadding={0} cellSpacing={0} className="down10px">
                                <tbody>
                                    {PageLocation}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
                </table>

                <table width="1200" cellPadding={0} cellSpacing={0} align="center" >
                    <tbody>                

                        <tr className="pagetop4">
                            <td colSpan={4} height="75" valign="left" className="activetenants width30">
                                {Translate("User settings")}
                            </td>
                        </tr>

                        <tr className="pagetop4">
                            <td colSpan={4} className="horizontalLine width30" >
                                <BlueBar/>
                            </td>
                        </tr>

                        <tr height="50"/>                
                    </tbody>
            </table>

                <table className="usermainsettingtable" width="1200" cellPadding={0} cellSpacing={0} align="center">
                <tbody>

                    <tr height="20" ></tr>
                   
                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizelarge">{Translate("Main settings")}</td>
                        <td></td>
                    </tr>
                    
                    <tr height="20"/>
                    
                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">{Translate("Name")}</td>
                    </tr>
                   
                   <tr height="10"/>

                    <tr height="40">
                        <td width="40"></td>
                        <td width="1000"><input value={mUser ? mUser.Name: ''} onChange={e=>SetName(e.target.value)} className="inputtd"></input></td>
                        <td></td>
                    </tr>

                    <tr height="20"/>
                
                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">{Translate("Email")}</td>
                    </tr>
                   
                    <tr height="10"/>

                    <tr height="40">
                        <td width="40"></td>
                        <td width="1000"><input value={mUser ? mUser.Email : '' } onChange={e=>SetEmail(e.target.value)} className="inputtd"></input></td>
                        <td></td>
                    </tr>

                    <tr height="20"/>


                    <tr height="20"></tr>

                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">{Translate("User API settings")}</td>
                    </tr>
                   
                    <tr height="10"/>

                    <tr height="80">
                        <td width="40"></td>
                        <td><textarea value={mUser ? mUser.ApiSettings : ''} onChange={e=>SetUserApiSetting(e.target.value)} className="textareaedituser"></textarea></td>
                        <td></td>
                    </tr>

                   <tr height="20"/>
                
                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall"><input checked={mUser ? mUser.IsRecognitionAdmin : false} onChange={e=>SetIsRecognitionAdmin(e.target.checked)} type="checkbox"/>
                            <span> </span> 
                            {Translate("CRM Integration Admin; required to configure the CRM integration settings of the user’s organization.")}
                        </td>
                    </tr>

                    <tr height="20"/>
                
                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall"><input checked={ mUser ? (mUser.IsTenantAdmin === true) : false} onChange={e=>SetIsTenantAdmin(e.target.checked)} type="checkbox"/> 
                        <span> </span>
                        {Translate("User Account Admin; required to configure the organization’s other user accounts.")}
                        </td>
                    </tr>
                
                <tr height="20"/>

                <tr>
                    <td width="40"></td>
                    {mUpdateSuccessfully ? <td className="bold green">{mUpdateResult}</td> : <td className="bold red">{mUpdateResult}</td>}
                </tr>

                <tr height="15"></tr>
                
                <tr>
                    <td width="40"></td>
                    <td className="bold fontsizesmall">
                        <button className="blackbackground white pointer borderradios buylicencebutton btn btn-dark" onClick={()=> handleSaveUserSettings()}>{Translate("Save user settings")}</button> 
                    </td>
                </tr>
                   
                <tr height="20"/>       

                </tbody>
            </table>
            
            {/*Free space*/}
            <table>
                <tbody>
                    <tr height="60"/>
                </tbody>
            </table>

            <table className="usermainsettingtable" width="1200" cellPadding={0} cellSpacing={0} align="center">
                <tbody>

                    <tr height="20" ></tr>
                   
                    <tr>
                       <td width="40"></td>
                       <td className="bold fontsizelarge">{Translate("License settings")}</td>
                       <td></td>
                    </tr>

                    <tr height="20" ></tr>

                <tr>
                    <td width="40"></td>
                    <td className="bold">{Translate("Choose a new option from the list to change the license")}</td>
                </tr>

                <tr height="20"/>
                
                <tr height="40">
                    <td width="40"></td>
                        <td width="1000">
                            <select
                                className="inputtd" onChange={e=>{SetLicenceGuid(e.target.value);SetRepaint(!mRepaint)}}>
                                    <option value={mUser ? (mUser.LicenceGuid ? mUser.LicenceGuid : "" ) : "" }>{mUser?.LicenceGuid ? mUser.LicenceName : "Please choose a license"}</option>
                                    <option value="None">{Translate("None")}</option>
                                    {mLicenceList.map((licence) => (
                                        <option key={licence.id} value={licence.LicenceGuid}>
                                            {licence.Name}
                                        </option>
                                    ))}
                            </select>
                    </td>
                </tr>
                
                <tr height="20"/>

                {((mUser && mUser.LicenceGuid) && (mLicenceGuid && mLicenceGuid !== mUser.LicenceGuid)) || (mLicenceGuid === "None" && mUser.LicenceGuid)  ? <tr height="20">
                                                                            <td width="40"></td>
                                                                            <td>
                                                                                <table>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td width="10"></td>
                                                                                            <td><input onChange={e=>SetExpireCurrentLicense(e.target.checked)} type="checkbox"/></td>
                                                                                            <td className="paddingleft bold">{Translate("Expire current license also for the tenant")}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                            <td></td>

                                                                     </tr>: <tr height="20">
                                                                     <td width="40"></td>
                                                                            <td>
                                                                                
                                                                            </td>
                                                                            <td></td>
                                                                     </tr> }
                                                                     <tr height="20"/>
                
                {(((mLicenceGuid && mLicenceGuid !== null) && mLicenceGuid !== 'None') && FindLicenceGuidInTenantLicences()) ? 
                    <tr>
                        <td width="40"></td>
                        <table>
                            <tbody>
                                <td><input onChange={e=>SetAutoBuyingLicense(e.target.checked)} type="checkbox"/></td>
                                <td>{Translate("Automatically add this license to this tenant if necessary")}</td>
                            </tbody>
                        </table>
                    </tr>
                    :
                    <tr></tr>
                }

                {(((mLicenceGuid && mLicenceGuid !== null) && mLicenceGuid !== 'None') && FindLicenceGuidInTenantLicences()) ? 
                    <tr height="20"></tr>
                    :
                    <tr></tr>
                }

                <tr>
                    <td width="40"/>
                    <button disabled={ mUser?.LicenceGuid ? ((mLicenceGuid !== mUser.LicenceGuid) ? false : true) : false } className="blackbackground white pointer borderradios buylicencebutton btn btn-dark" onClick={()=> AssignNewLicense()}>{Translate("Change license")}</button> 
                </tr>
                   
                <tr height="20"/>
            </tbody>
            </table>
            
            <table>
                <tbody>
                    <tr height="80"/>
                </tbody>
            </table>



            <table className="usermainsettingtable" width="1200" cellPadding={0} cellSpacing={0} align="center">
            <tbody>

                <tr height="20" ></tr>
                   
                <tr>
                    <td width="40"></td>
                    <td className="bold fontsizelarge">{Translate("Change password")}</td>
                    <td></td>
                </tr>
                
                <tr height="20"/>
                
                <tr>
                    <td width="40"></td>
                    <td>
                        {Translate("New password must comply with the following")}
                    </td>
                </tr>
                
                <tr height="8"/>
                   
                <tr>
                    <td width="40"></td>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <td width="8"/>
                                    <td className="dot"></td>
                                    <td className="fontsizesmall paddingleft">{Translate("Must contain between 8 and 50 characters")}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr height="8"/>
                
                <tr>
                    <td width="40"></td>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <td width="8"/>
                                    <td className="dot"></td>
                                    <td className="fontsizesmall paddingleft">
                                        {Translate("Must contain at least 1 letter")}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr height="8"/>

                <tr>
                    <td width="40"></td>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <td width="8"/>
                                    <td className="dot"></td>
                                    <td className="fontsizesmall paddingleft">
                                        {Translate("Must contain at least 1 digit")}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr height="8"/>
                   
                <tr>
                    <td width="40"></td>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <td width="8"/>
                                    <td className="dot"></td>
                                    <td className="fontsizesmall paddingleft">
                                        {Translate("Must contain at least 1 special character [~`!@#$%^&*()_+=|[]:;']")}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr height="20"></tr>

                <tr>
                    <td width="40"></td>
                    <td className="bold fontsizesmall">{Translate("Password")} <span className="red"> *</span></td>
                </tr>
                   
                <tr height="10"/>

                <tr height="40">
                    <td width="40"></td>
                    <td><input type={mPasswordVisible ? "text" : "password"}  onChange={e=>SetUserPassword(e.target.value)} className="inputtd"></input>
                        <span className="passwordtoggleicon" onClick={() => SetPasswordVisible(!mPasswordVisible)}>{mPasswordVisible ? '👁️' : '👁️‍🗨️'} </span>
                    </td>
                    <td></td>
                </tr>

                <tr height="20"/>

                <tr>
                    <td width="40"></td>
                    <td width="100"><PasswordStrengthBar password={mUserPassword} /></td>
                </tr> 

                <tr height="20"/>

                <tr>
                    <td width="40"></td>
                    <td className="bold fontsizesmall">{Translate("Confirm new password")} <span className="red"> * </span></td>
                </tr>
                   
                <tr height="10"/>

                <tr height="40">
                    <td width="40"></td>
                    <td><input type="password" onChange={e=>SetConfirmPassword(e.target.value)} className="inputtd"></input></td>
                    <td></td>
                </tr>

                <tr height="20"></tr>

                <tr>
                    <td width="40"/>
                        {mChangePasswordSuccessfully ? <td className="green bold">{mChangePasswordResult}</td> : <td className="red bold">{mChangePasswordResult}</td> }
                </tr>

                <tr height="15"></tr>
                
                <tr>
                    <td width="40"></td>
                    <td className="bold fontsizesmall">
                        <button className="saveusersettingsbutton bold" onClick={()=>handleChangePassword()}>{Translate("Change password")}</button> 
                    </td>
                </tr>
                
                <tr height="20"/>       
                </tbody>
            </table>

            <br/>
            <br/>

            {/*Delet User Table*/}
            <table className="usermainsettingtable" width="1200" cellPadding={0} cellSpacing={0} align="center">
            <tbody>

                <tr height="20" ></tr>
                
                <tr>
                    <td width="40"></td>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <td><img alt="Close logo" width="20" src={CloseLogo}/></td>
                                    <td className="bold fontsizelarge paddingleft "><span> </span>{Translate("Delete this user")}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr height="20" />
                    
                <tr>
                    <td width="40"></td>
                    <td className="bold fontsizesmall">
                        <button className="blackbackground white pointer borderradios buylicencebutton btn btn-dark" onClick={()=>{handleDeleteUser()}}>{Translate("Delete user")}</button> 
                    </td>
                </tr>   
                   
                <tr height="40"/>
            </tbody>
            </table>

            {/*FreeSpace*/}
            <table>
                <tbody>
                    <tr height="80"></tr>
                </tbody>
            </table>

            {/*footerTable*/}
            <table>
                <tbody>
                    <tr height="60"></tr>
                </tbody>
            </table>

            <Footer/>

            {/*Animation part*/}
            <Modal animation={false} style={{opacity:1}} show={mExpireLicence !== ""} onHide={() => SetExpireLicence("")} centered={true}>
                <Modal.Header closeButton={true}>
                    <Modal.Title align="center"><span align="center">{("Are you sure for expiring this license?")}</span></Modal.Title>
                </Modal.Header>
                <Modal.Footer align="center">
                    <Button variant="dark" onClick={() => ExpireLicence()}>OK</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="dark" onClick={() =>SetExpireLicence("")}>Cancel</Button>
                </Modal.Footer>
            </Modal>

            {/*Animation part*/}
            <Modal animation={false} style={{opacity:1}} show={mAssignNewLicenceError !== ""} onHide={() => SetAssignNewLicenceError("")} centered={true}>
                <Modal.Header closeButton={true}>
                    <Modal.Title align="center"><span align="center">{
                        (mAssignNewLicenceError === "Not enough licenses" ? Translate("You don't have this license, you can buy it or assign another license.") : "" )}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer align="center">
                    <Button variant="dark" onClick={() => {navigate('../buylicence?tenantguid=' + (mUser ? mUser.TenantGuid : ""));SetAssignNewLicenceError("");}}>{Translate("Buy licenses")}</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="dark" onClick={() =>SetAssignNewLicenceError("")}>Cancel</Button>
                </Modal.Footer>
            </Modal>
            
        </span>
        )
    
}

export default EditUser