export function TranslationsNederlands() 
{
    return [
        {"id":"# Bought licenses","value":"# Gekochte licenties"},
        {"id":"A user with this email address is already registered, please try with another email address","value":"Er is al een gebruiker met dit e-mailadres geregistreerd. Probeer het met een ander e-mailadres"},
        {"id":"Active","value":"Actief"},
        {"id":"Active tenants","value":"Actieve tenants"},
        {"id" :"Add new user","value":"Voeg een nieuwe gebruiker toe"},
        {"id":"Add","value":"toevoegen"},
        {"id":"Add user","value":"Gebruiker toevoegen"},
        {"id":"Add tenant","value":"Tenant toevoegen"},
        {"id":"Add to tenant","value":"Toevoegen aan tenant"},
        {"id":"Add user","value":"Voeg gebruiker toe"},
        {"id":"Address","value":"Adres"},
        {"id":"and","value":"en"},
        {"id":"API key","value":"API sleutel"},
        {"id":"API settings","value":"API instellingen"},
        {"id":"Available licenses","value":"Beschikbare licenties"},
        {"id":"Block","value":"blok"},
        {"id":"Bought licenses","value":"Licenties gekocht"},
        {"id":"Buy license","value":"Licentie kopen"},
        {"id":"By clicking buy license you agree to our","value":"Door op licentie kopen te klikken, gaat u akkoord met onze"},
        {"id":"Change password","value":"Verander wachtwoord"},
        {"id":"City","value":"Stad"},
        {"id":"Company name","value":"Bedrijfsnaam"},
        {"id":"Confirm new password","value":"Bevestig nieuw wachtwoord"},
        {"id":"Count","value":"Aantal"},
        {"id":"Country","value":"Land"},
        {"id":"Created on","value":"Gemaakt op"},
        {"id":"Custom ID","value":"Aangepaste ID"},
        {"id":"Delete this user","value":"Verwijder deze gebruiker"},
        {"id":"Delete user","value":"Verwijder gebruiker"},
        {"id":"Description","value":"Beschrijving"},
        {"id":"Default tenant API settings","value":"Standaard tenant API instellingen"},
        {"id":"Default user API settings","value":"Standaard gebruiker API instellingen"},
        {"id":"Default settings","value":"Standaard instellingen"},   
        {"id":"Delete this tenant","value":"Verwijder deze tenant"},
        {"id":"Disclaimer","value":"Disclaimer"},
        {"id":"Edit information","value":"Informatie bewerken"},
        {"id":"Edit tenant","value":"Tenant bewerken"},
        {"id":"E-mail","value":"E-mail"},
        {"id":"Email","value":"E-mail"},
        {"id":"Enable recognition configuration","value":"Herkenningsconfiguratie inschakelen"},
        {"id":"Expire","value":"Verlopen"},
        {"id":"Edit","value":"Bewerken"},
        {"id":"Home","value":"Home"},
        {"id":"Information","value":"Informatie"},
        {"id":"Invite","value":"Uitnodigen"},
        {"id":"Is tenant administrator","value":"Is tenantbeheerder"},
        {"id":"Is user recognition admin","value":"Is gebruikersherkenningsbeheerder"},
        {"id":"Is user tenant admin","value":"Is gebruiker-tenantbeheerder"},
        {"id":"Phone number","value":"Telefoonnummer"},
        {"id":"License","value":"Licentie"},
        {"id":"Licenses","value":"Licenties"},
        {"id":"Main settings","value":"Hoofdinstellingen"},
        {"id":"Must contain between 8 and 50 characters","value":"Moet tussen 8 en 50 tekens bevatten"},
        {"id":"Must contain at least 1 letter","value":"Moet minimaal 1 letter bevatten"},
        {"id":"Must contain at least 1 digit","value":"Moet minimaal 1 cijfer bevatten"},
        {"id":"Must contain at least 1 special character [~`!@#$%^&*()_+=|[]:;']","value":"Moet minimaal 1 speciaal teken bevatten [~`!@#$%^&*()_+=|[]:;']"},
        {"id":"Name","value":"Naam"},
        {"id":"Name tenant","value":"Naam tenant"},
        {"id":"New password must comply with the following","value":"Nieuw wachtwoord moet aan het volgende voldoen"},
        {"id":"No license available","value":"Geen licentie beschikbaar"},
        {"id":"No users available","value":"Geen gebruikers beschikbaar"},
        {"id":"None","value":"Geen"},
        {"id":"password","value":"wachtwoord"},
        {"id":"Password","value":"Wachtwoord"},
        {"id":"Please enter your dashboard password","value":"Voer uw dashboardwachtwoord in"},
        {"id":"Please find a range of support resources on these pages. More will be added soon.","value":"Op deze pagina's vindt u een reeks ondersteuningsbronnen. Binnenkort zullen er meer worden toegevoegd."},
        {"id":"Please select the license","value":"Selecteer de licentie"},
        {"id":"Postal code","value":"Postcode"},
        {"id":"Phone","value":"Telefoon"},
        {"id":"Privacy Policy","value":"Privacybeleid"},
        {"id":"Quantity","value":"Hoeveelheid"},
        {"id":"QUANTITY","value":"HOEVEELHEID"},
        {"id":"Reseller users","value":"Reseller gebruikers"},
        {"id":"Retrieving the users","value":"Het ophalen van de gebruikers"},
        {"id":"Retrieving available licenses","value":"Bezig met ophalen van beschikbare licenties"},   
        {"id":"Save settings","value":"Instellingen opslaan"},
        {"id":"Save user settings","value":"Bewaar gebruikersinstellingen"},     
        {"id":"Save user settings","value":"Bewaar gebruikersinstellingen"},
        {"id":"Search","value":"Zoeken"},
        {"id":"Security Policy","value":"Veiligheidsbeleid"},
        {"id":"SELECT LICENSE","value":"SELECTEER LICENTIE"},
        {"id":"Settings","value":"Instellingen"},
        {"id":"Setup","value":"Opgericht"},
        {"id":"Sign in","value":"Inloggen"},
        {"id":"Sign in page","value":"Inloggen pagina"},
        {"id":"Subscription","value":"Abonnement"},
        {"id":"Tenant","value":"Tenant"},
        {"id":"Terms & Conditions","value":"Algemene voorwaarden"},
        {"id":"Tenant is not found","value":"Tenant wordt niet gevonden"},
        {"id":"Tenants","value":"Tenants"},
        {"id":"Tenant API setting","value":"Tenant API instellingen"},
        {"id":"Tenant licenses","value":"Tenant licenties"},
        {"id":"Tenant settings","value":"Tenant instellingen"},
        {"id":"Tenant users","value":"Tenant gebruikers"},
        {"id":"Terms and Condition","value":"Algemene voorwaarden"},
        {"id":"Users","value":"Gebruikers"},
        {"id":"User API settings","value":"Gebruiker API instellingen"},
        {"id":"User is not found","value":"Gebruiker wordt niet gevonden"},
        {"id":"User settings","value":"Gebruikers instellingen"},
        {"id":"Update information","value":"Update informatie"},
        {"id":"VAT number","value":"BTW nummer"},
        {"id":"View info","value":"Toon informatie"},
        {"id":"View licenses","value":"Toon licenties"},
        {"id":"View users","value":"Toon gebruikers"},
        {"id":"You are not authorized to retrieving this","value":"U bent niet bevoegd om deze tenant op te halen. Meld u aan met een ander account of ga terug naar uw tenants"},
        {"id":"Your tenants","value":"Uw tenants"}


    ]
}