import React from "react";

import Reseller from "./Reseller"

import "./HomePage.css";

function HomePage(){

    return(
        <span>
            <Reseller/>
        </span>
    )
}

export default HomePage;